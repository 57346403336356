import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import { useAppData } from '../../../hooks/useAppData';
import { useUrlParams } from '../../../hooks/useLocalizedHistoryPush';
import { FORMS } from '../../../utils/constants';
import { emailFormat, required } from '../../../utils/validators';
import Button from '../../common/Button';
import SimpleField from '../../Form/Field';
import SsoBanner from '../../SsoBanner';

const LoginForm = ({ error, handleSubmit, submitting, valid }) => {
    const { t } = useTranslation();
    const { AUTH_URL } = useAppData();
    const updateUrlParams = useUrlParams();

    return (
        <>
            <h1 className="title">{t('auth.login.title')}</h1>
            <SsoBanner />
            <form className="base-margin" method="post" onSubmit={handleSubmit}>
                {error && <div className="error">{error}</div>}
                <Field
                    component={SimpleField}
                    label={t('common.email_address')}
                    name="email"
                    type="email"
                    validate={[required, emailFormat]}
                />
                <Field
                    component={SimpleField}
                    label={t('auth.login.password.placeholder')}
                    name="password"
                    type="password"
                    validate={[required]}
                />
                <button
                    className="link strong"
                    onClick={() => updateUrlParams({ open: FORMS.LOSTPASSWORD })}
                    type="button"
                >
                    {t('auth.login.forgotten_password')}
                </button>
                <div>
                    <Button
                        className="button--block button--black base-margin"
                        disabled={!valid}
                        submitting={submitting}
                        text={t('auth.login.log_in')}
                        submit
                    />
                    <Button
                        className="button--block button--outline base-margin"
                        img="/img/icons/google.svg"
                        onClick={() => {
                            window.location.href = `${AUTH_URL}/api/auth/marco-v4/google`;

                            return null;
                        }}
                        text={t('auth.signin.google')}
                    />
                </div>
                <div className="base-margin auth-form__redirectLink">
                    <span>{t('auth.login.no_account')}</span>
                    {' '}
                    <button
                        className="link strong"
                        onClick={() => updateUrlParams({ open: FORMS.SIGNIN })}
                        type="button"
                    >
                        {t('auth.login.go_to_signup')}
                    </button>
                </div>
            </form>
        </>
    );
};

LoginForm.propTypes = {
    error: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    valid: PropTypes.bool,
};

export default reduxForm({
    form: 'loginForm',
})(LoginForm);
