import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import requireAuth from "../hoc/requireAuth";
import requireUser from "../hoc/requireUser";
import Account from "../routes/Account";
import Admin from "../routes/Admin";
import CheckLogin from "../routes/CheckLogin";
import Home from "../routes/Home";
import Confidentiality from "../routes/Legal/Confidentiality";
import LegalNotices from "../routes/Legal/LegalNotices";
import TermOfServices from "../routes/Legal/TermOfServices";
import NotFound from "../routes/NotFound";
import PasswordReset from "../routes/PasswordReset";
import Exercise from "../routes/personality-form/Exercise";
import ExerciseRedirector from "../routes/personality-form/ExerciseRedirector";
import Step7 from "../routes/personality-form/Form";
import LastResults from "../routes/personality-form/LastResults";
import RestitutionWrapper from "../routes/personality-form/RestitutionWrapper";
import Results from "../routes/personality-form/Results";
import Step6Rank from "../routes/personality-form/Step6-rank";
import StepTransition from "../routes/personality-form/StepTransition";
import StepTransitionGif from "../routes/personality-form/StepTransitionGif";
import { languageSelector } from "../store/slices/language";

const LocalizedRoutes = () => {
    const { language } = useSelector(languageSelector);

    const Routes = useMemo(
        () => (
            <Switch>
                <Route component={Home} path={`/${language}`} exact />
                <Route component={requireAuth(CheckLogin)} path={`/${language}/check-login`} exact />
                <Route component={RestitutionWrapper} path={`/${language}/pdf/restitution/:formId/:phase`} exact />
                <Route component={requireAuth(Admin)} path="/fr/admin" exact />
                <Route component={() => <Redirect to="/fr/admin" />} path={`/${language}/admin`} exact />
                <Route component={Confidentiality} path={`/${language}/confidentiality`} exact />
                <Route component={LegalNotices} path={`/${language}/legalnotices`} exact />
                <Route component={PasswordReset} path={`/${language}/password-reset/:token`} exact />
                <Route component={TermOfServices} path={`/${language}/tos`} exact />
                <Route component={Results} path={`/${language}/results/:formId`} exact />
                <Route component={requireAuth(requireUser(LastResults))} path={`/${language}/last-results`} exact />
                <Route component={requireAuth(requireUser(Account))} path={`/${language}/account`} exact />
                <Route
                    component={requireAuth(requireUser(ExerciseRedirector))}
                    path={`/${language}/step/:step([1-6]{1})`}
                    exact
                />
                <Route
                    component={requireAuth(requireUser(StepTransition))}
                    path={`/${language}/step/:step([0-6]{1})/transition`}
                    exact
                />
                <Route
                    component={requireAuth(requireUser(Exercise))}
                    path={`/${language}/step/:step([1-6]{1})/:substep(1?[0-9]{1,2}|2[0-6][0-9]|27[0-6])`}
                    exact
                />
                <Route component={requireAuth(requireUser(Step6Rank))} path={`/${language}/step/6/rank`} exact />
                <Route component={requireAuth(requireUser(Step7))} path={`/${language}/step/7`} exact />
                <Route
                    component={requireAuth(requireUser(StepTransitionGif))}
                    path={`/${language}/processing/:formId`}
                    exact
                />
                <Route component={NotFound} path={`/${language}/*`} status={404} exact />
            </Switch>
        ),
        [language]
    );

    return Routes;
};

export default LocalizedRoutes;
