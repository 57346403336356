import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useWindowSize from '../../../hooks/useWindowSize';
import Button from '../../common/Button';
import LanguageSwitcher from '../../common/LanguageSwitcher';
import HeaderMenu from './HomeHeaderMenu';
import './style.scss';

const HomeHeader = ({ openLogin, openSignup }) => {
    const { t } = useTranslation();
    const isMobile = 'xs' === useWindowSize().size;

    return (
        <header className="home-header" role="banner">
            <div className="home-header__header-menu__wrapper">
                <HeaderMenu
                    className="home-header__header-menu"
                    leftElement={(
                        <Link to="/account">
                            <img alt="Logo" src="/img/logos/logo-white.svg" />
                        </Link>
                    )}
                    rightElement={(
                        <div className="home-header__header-menu--right">
                            <Button
                                className="header-menu__login-button"
                                img="/img/icons/person.svg"
                                onClick={() => openLogin()}
                                text={t('header_menu.login')}
                            />
                            {!isMobile && (
                                <>
                                    <div className="home-header__header-menu__pipe" />
                                    <LanguageSwitcher className="language-switcher__small" color="black" shortLabel />
                                </>
                            )}
                        </div>
                    )}
                />
            </div>
            <div className="home-header__container">
                <div className="home-header__intro">
                    <h1 className="home-header__title">{t('home.header.title')}</h1>
                    <h2 className="home-header__subtitle">{t('home.header.subtitle')}</h2>
                    <Button onClick={openSignup} text={t('home.start_button')} />
                </div>
                <div className="home-header__wrapper-illustration">
                    <img
                        alt="header-illustration"
                        className="home-header__illustration"
                        src="/img/home/marco-home.svg"
                    />
                </div>
            </div>
        </header>
    );
};

HomeHeader.propTypes = {
    openLogin: PropTypes.func,
    openSignup: PropTypes.func,
};

export default HomeHeader;
