import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { STATUS_ADMIN } from '../../../constants';
import AuthForms from '../../components/AuthFormsModal';
import HomeContent from '../../components/home/HomeContent';
import HomeFooter from '../../components/home/HomeFooter';
import HomeHeader from '../../components/home/HomeHeader';
import { useAppData } from '../../hooks/useAppData';
import useLocalizedHistoryPush, { getUrlParams, useUrlParams } from '../../hooks/useLocalizedHistoryPush';
import { FORMS } from '../../utils/constants';
import './style.scss';

const Home = ({ id, status }) => {
    const push = useLocalizedHistoryPush();
    const updateUrlParams = useUrlParams();
    const { open: openForm } = getUrlParams();
    const { isWebview } = useAppData();

    const openSigninForm = () => {
        if (id) {
            const path = STATUS_ADMIN === status ? '/admin' : '/account';

            return push(path);
        }

        return updateUrlParams({ open: FORMS.SIGNIN });
    };

    const openLoginForm = () => {
        if (id) {
            const path = STATUS_ADMIN === status ? '/admin' : '/account';

            return push(path);
        }

        return updateUrlParams({ open: FORMS.LOGIN });
    };

    return (
        <div className="home">
            {openForm && <AuthForms />}
            <HomeHeader openLogin={openLoginForm} openSignup={openSigninForm} />
            <HomeContent onClick={openSigninForm} />
            {!isWebview && <HomeFooter />}
        </div>
    );
};

Home.propTypes = {
    id: PropTypes.string,
    status: PropTypes.string,
};

const mapStateToProps = ({ auth: { id, status } }) => ({ id, status });

export default connect(mapStateToProps)(Home);
