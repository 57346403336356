import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { Trans, useTranslation } from "react-i18next";
import { connect, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { compose } from "redux";
import { change, Field } from "redux-form";
import Phase from '../../../../constants/phase.types';
import Navigation from "../../../components/common/Navigation";
import Container from "../../../components/layout/Container";
import MainWithStep from "../../../components/layout/MainWithStep";
import Panel from "../../../components/layout/Panel";
import DescriptionExercise from "../../../components/PanelDescription/DescriptionExercise";
import Instructions from "../../../components/PanelDescription/Instructions";
import StepList from "../../../components/PanelDescription/StepList";
import ProgressionCircles from "../../../components/ProgressionCircles";
import SmileyBar from "../../../components/SmileyBar";
import requireForm from "../../../hoc/requireForm";
import requireLastFormId from "../../../hoc/requireLastFormId";
import useLocalizedHistoryPush from "../../../hooks/useLocalizedHistoryPush";
import useWindowSize from "../../../hooks/useWindowSize";
import { computePiscoStats, getLastForms, saveResponse } from "../../../store/actions";
import { phaseSelector } from "../../../store/slices/exerciseData";
import {
    getExerciseFirstQuestionNumber,
    getExerciseInfos,
    getQuestion,
    isExerciseFirstQuestion,
    isExerciseLastQuestion,
    isFinalQuestion,
} from "../../../utils/exercises";
import "./style.scss";

const renderInstructions = (onClose, phase, step) => (
    <Instructions onClose={onClose}>
        <DescriptionExercise phase={phase} step={step} />
    </Instructions>
)

const Exercise = ({ save, lastFormId }) => {
    const push = useLocalizedHistoryPush();
    const { t } = useTranslation();
    const { step, substep } = useParams();
    const [smileyValue, setSmileyValue] = useState(null);
    const dispatch = useDispatch();
    const exerciseNumber = useMemo(() => parseInt(step, 10), [step]);
    const questionNumber = useMemo(() => parseInt(substep, 10), [substep]);
    const phase = useSelector(phaseSelector);
    const { size } = useWindowSize();
    const currentFormId = useSelector(state => state.reconnect.useLastCompletedForm
        ? state.reconnect.lastCompletedForm?.id
        : state.reconnect.lastForm?.id
    );

    const showInstructions = () => {
        confirmAlert({
            /* eslint-disable-next-line react/prop-types */
            customUI: ({ onClose }) => renderInstructions(onClose, phase, step),
        });
    };

    useEffect(() => {
        if (!currentFormId) {
            dispatch(getLastForms());
        }
    }, []);

    useEffect(() => {
        if (phase !== Phase.MINI_MARCO && isExerciseFirstQuestion(phase, exerciseNumber, questionNumber)) {
            // only show instructions on mobile devices
            if (size === 'xs') {
                showInstructions();
            }
        }
    }, [exerciseNumber, questionNumber]);

    const goToNextQuestion = useCallback(
        async value => {
            // Save in database
            await save(currentFormId, exerciseNumber, questionNumber, value);

            // Redirect
            if (isExerciseLastQuestion(phase, exerciseNumber, questionNumber)) {
                if (isFinalQuestion(phase, exerciseNumber, questionNumber)) {
                    const stats = await dispatch(computePiscoStats(currentFormId, phase));
                    if (null === stats.minor || null === stats.major) {
                        push(`/step/${exerciseNumber}/rank`);

                        return;
                    }
                }
                // do not display transitions on mini marco and directly jump to the next exercise
                if (phase === Phase.MINI_MARCO && exerciseNumber !== 6) {
                    const nextExerciseNumber = exerciseNumber + 1;
                    const firstQuestionNextExercise = getExerciseFirstQuestionNumber(phase, nextExerciseNumber);
                    push(`/step/${nextExerciseNumber}/${firstQuestionNextExercise}`);
                } else {
                    push(`/step/${exerciseNumber}/transition`);
                }

                return;
            }

            setSmileyValue(null);
            push(`/step/${exerciseNumber}/${questionNumber + 1}`);
        },
        [exerciseNumber, questionNumber, lastFormId, currentFormId]
    );

    const onBack = () => push(`/step/${exerciseNumber}/${questionNumber - 1}`);

    const exerciseInfos = useMemo(() => getExerciseInfos(phase, exerciseNumber), [exerciseNumber]);
    const question = useMemo(() => getQuestion(phase, exerciseNumber, questionNumber), [
        exerciseNumber,
        questionNumber,
    ]);

    return (
        <Container>
            <Panel>
                <StepList phase={phase} step={exerciseNumber} />
            </Panel>
            <MainWithStep
                className="exercise"
                footer={
                    <Navigation
                        current={
                            exerciseInfos
                                ? exerciseInfos.questions.findIndex(({ key }) => questionNumber === key)
                                : null
                        }
                        disableBack={isExerciseFirstQuestion(phase, exerciseNumber, questionNumber)}
                        onBack={onBack}
                        total={exerciseInfos ? exerciseInfos.questions.length : null}
                        displayBar
                    />
                }
                subheader={t("exercise.subheader", { stepNumber: exerciseNumber })}
                subtitle={
                    <ProgressionCircles exerciseNumber={exerciseNumber} phase={phase} questionNumber={questionNumber} />
                }
                title={t(exerciseInfos.stepName)}
            >
                <div className="exercise__container">
                    <div className="exercise__text">
                        <h3>{t(exerciseInfos.instruction)}</h3>
                        <p>
                            <Trans i18nKey={question.question} />
                        </p>
                    </div>
                    <div className="smiley-bar-container">
                        <Field
                            component={SmileyBar}
                            name={`step${step}.${substep}`}
                            onChange={value => {
                                setSmileyValue(value);
                                setTimeout(() => goToNextQuestion(value), 250);
                            }}
                            value={smileyValue}
                        />
                    </div>
                </div>
            </MainWithStep>
        </Container>
    );
};

Exercise.propTypes = {
    lastFormId: PropTypes.string,
    save: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    save: saveResponse,
    updateField: change,
};

export default compose(requireForm, requireLastFormId, connect(null, mapDispatchToProps))(Exercise);
