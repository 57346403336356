import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getAllLangs } from '../../utils/lang';
import Button from '../common/Button';
import Modal from '../common/Modal';
import SelectField from '../Form/SelectField';
import './styles.scss';

const PdfModal = ({ language, onClose, formId }) => {
    const { t } = useTranslation();
    const languages = getAllLangs();

    const [pdfLanguage, setPdfLanguage] = useState(language);

    return (
        <Modal
            closeModal={onClose}
            fullscreen
        >
            <div className="modal-pdfLanguage">
                <SelectField
                    input={{
                        name: 'language',
                        onChange: (value) => setPdfLanguage(value),
                        value: pdfLanguage,
                    }}
                    label={t('pdfModal.field.language.title')}
                    menuPortalTarget={document.body}
                    options={languages.map(({ keyLang, label }) => ({
                        value: keyLang,
                        label,
                    }))}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 200 }) }}
                />
                <a href={`/api/pdf/download/${formId}/${pdfLanguage}?${(new Date()).getTime()}`} rel="noreferrer" target="_blank">
                    <Button
                        aria-label={t('common.download_report')}
                        className="button--black button--block base-margin"
                        text={t('common.download_report')}
                    />
                </a>
            </div>
        </Modal>
    );
};

PdfModal.propTypes = {
    formId: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default PdfModal;
