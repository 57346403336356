import { useDispatch } from "react-redux";
import { logout } from "../jt-client/actions";
import { deleteLastForm } from "../store/slices/reconnect";
import { cleanUser } from "../store/slices/user";
import { ROUTES } from "../utils/routes";
import useLocalizedHistoryPush from "./useLocalizedHistoryPush";

const useLogout = () => {
    const dispatch = useDispatch();
    const push = useLocalizedHistoryPush();

    return () => {
        push(ROUTES.Homepage);
        dispatch(logout());
        dispatch(deleteLastForm());
        dispatch(cleanUser());
    };
};

export default useLogout;
