import React, { useCallback } from 'react';
import { useSelector } from "react-redux";
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import { getUrlParams, useUrlParams } from '../../hooks/useLocalizedHistoryPush';
import { login, signup } from '../../jt-client/actions/auth';
import { passwordLost } from '../../jt-client/actions/user';
import { languageSelector } from '../../store/slices/language';
import { FORMS } from '../../utils/constants';
import Modal from '../common/Modal';
import LoginForm from './LoginForm';
import PasswordLostForm from './PasswordLostForm';
import SignInForm from './SigninForm';
import './style.scss';

const onLogin = (values, dispatch) => dispatch(login(values));
const onSignIn = (values, dispatch) => dispatch(signup(values));

const AuthForms = () => {
    const { language } = useSelector(languageSelector);
    const { push } = useHistory();
    const updateUrlParams = useUrlParams();
    const { open: openForm } = getUrlParams();
    const { search } = useLocation();

    const onPasswordLost = useCallback((values, dispatch) => dispatch(passwordLost({
        ...values,
        appName: 'MARCO_V4',
    })), []);

    const query = new URLSearchParams(search);
    const origin = query.get('utm_source');

    return (
        <Modal closeModal={() => updateUrlParams({ open: null })} fullscreen>
            <div className="auth-form">
                {FORMS.LOGIN === openForm && (
                    <LoginForm
                        onSubmit={onLogin}
                        onSubmitSuccess={() => push('/check-login')}
                        onSwitch={(formEnum) => updateUrlParams({ open: formEnum })}
                    />
                )}
                {FORMS.SIGNIN === openForm && (
                    <SignInForm
                        initialValues={{ language, origin }}
                        onSubmit={onSignIn}
                        onSubmitSuccess={() => push('/check-login')}
                        onSwitch={(formEnum) => updateUrlParams({ open: formEnum })}
                    />
                )}
                {FORMS.LOSTPASSWORD === openForm && (
                    <PasswordLostForm
                        onSubmit={onPasswordLost}
                        onSubmitSuccess={() => updateUrlParams({ open: FORMS }.LOGIN)}
                        onSwitch={(formEnum) => updateUrlParams({ open: formEnum })}
                    />
                )}
            </div>
        </Modal>
    );
};

export default withRouter(AuthForms);
